import React, { useState, useEffect } from "react";
import styled from "styled-components";
import darwinImg from "../images/darwin.png";
import Http from "../helpers/Http";

const Service = () => {
  const [services, setServices] = useState([]);
  useEffect(() => {
    try {
      Http.get("content/type/last/2/6")
        .then((response) => {
          console.log("services: ", response.data.contents);
          setServices(response.data.contents);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  }, []);

  return (
    <Container>
      <Title>Services</Title>
      <Grid>
        {services.map((item, key) => (
          <ServiceCard key={key} item={item} />
        ))}
      </Grid>
    </Container>
  );
};

export default Service;

const ServiceCard = ({ item }) => {
  return (
    <Card>
      <ImageContainer>
        <Image src={darwinImg} alt="darwin" />
        <Dashed />
      </ImageContainer>
      <CardTitle>{item.title}</CardTitle>
      <CardBody>
        <div
          dangerouslySetInnerHTML={{
            __html: item.description.substring(0, 100),
          }}
        />
      </CardBody>
      <CardLink href={`/detail/?id=${item.id}&type=SERVICE`}>
        Learn more...
      </CardLink>
    </Card>
  );
};

const ImageContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;
const Image = styled.img`
  height: 64px;
  width: 64px;
`;
const Dashed = styled.div`
  flex: 1;
  border-bottom: 1px dashed #efefef;
`;

const Container = styled.div`
  background-color: #fff;
  padding: 32px 4%;
`;

const Title = styled.h2`
  margin: 0;
  text-align: center;
  text-transform: uppercase;
  font-size: 36px;
  @media (max-width: 480px) {
    font-size: 30px;
  }
`;

const Grid = styled.div`
  max-width: 1024px;
  margin: 4% auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 40px;

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 540px) {
    grid-template-columns: 1fr;
  }
`;

const Card = styled.div`
  /* padding: 15px;
  box-shadow: 0 0 2px 1px rgb(0 0 0 / 8%);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  &:hover {
    cursor: pointer;
    box-shadow: 0 0 2px 1.5px rgb(0 0 0 / 12%);
  } */
`;

const CardTitle = styled.h3`
  text-transform: uppercase;
  color: var(--base-color);
  font-size: 22px;
  margin: 0;
`;
const CardBody = styled.p`
  color: #9d9087;
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.4px;
`;
const CardLink = styled.a`
  color: var(--base-color);
  font-size: 16px;
  line-height: 1.5;
  letter-spacing: 0.4px;
`;
