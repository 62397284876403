import * as React from "react";
import Layout from "../components/Layout";
import Header from "../components/Header";
import Footer from "../components/Footer";
import DailyPrayerTimes from "../components/DailyPrayerTimes";
import HadithOfTheDay from "../components/HadithOfTheDay";
import DonatePlayStore from "../components/DonatePlayStore";
import Services from "../components/Services";
import ProgramsAndEvent from "../components/ProgramsAndEvent";
import { Main, BackgroundImage, HadithImage } from "../styles";
import hadithImage from "../images/hadithImage.jpeg";

const IndexPage = () => {
  return (
    <Layout>
      <Header />
      <br />
      <br />
      <br />
      <Main>
        <HadithImage src={hadithImage}>
          <HadithOfTheDay />
        </HadithImage>
        <BackgroundImage style={{ paddingTop: "20px" }}>
          <DailyPrayerTimes />
          <DonatePlayStore />
        </BackgroundImage>
        <Services />
        <ProgramsAndEvent />
      </Main>
      <Footer />
    </Layout>
  );
};

export default IndexPage;
