import { Grid } from "@material-ui/core";
import React from "react";
import styled from "styled-components";
import donation from "../images/donation.jpg";
import playstore from "../images/playstore.jpg";
import getAppAppstore from "../images/getAppAppstore.png";
import getAppPlaystore from "../images/getAppPlaystore.png";

const DonatePlayStore = () => {
  return (
    <Container>
      <form action="https://www.paypal.com/donate" method="post" target="_top">
        <input type="hidden" name="hosted_button_id" value="SG93BXEVF55Y2" />
        <input
          type="image"
          src={donation}
          style={{
            width: "100%",
            objectFit: "contain",
            objectPosition: "center",
            borderRadius: "6px",
          }}
          name="submit"
          title="PayPal - The safer, easier way to pay online!"
          alt="Donate with PayPal button"
        />
        <img
          alt=""
          border="0"
          src="https://www.paypal.com/en_GB/i/scr/pixel.gif"
          width="1"
          height="1"
        />
      </form>
      <img
        src={playstore}
        alt="Download App from Playstore"
        style={{
          width: "100%",
          objectFit: "contain",
          objectPosition: "center",
          borderRadius: "6px",
        }}
      />
      <Grid container spacing={2} style={{ marginTop: "5px" }}>
        {/* Download from appstore button */}
        <Grid item xs={4} md={3}>
          <a
            href="https://play.google.com/store/apps/details?id=com.sulaman.darwenmosque&gl=GB"
            target="_blank"
            rel="Download from appstore button noreferrer">
            <img
              src={getAppPlaystore}
              style={{ width: "100%", borderRadius: "10px" }}
            />
          </a>
        </Grid>
        {/* Download from playstore button */}
        <Grid item xs={4} md={3}>
          <a
            href="https://apps.apple.com/gb/app/darwen-mosque-app/id1579314925"
            target="_blank"
            rel="Download from playstore button noreferrer">
            <img
              src={getAppAppstore}
              style={{ width: "100%", borderRadius: "10px" }}
            />
          </a>
        </Grid>
      </Grid>
    </Container>
  );
};

export default DonatePlayStore;

const Container = styled.div`
  background-color: #fff;
`;
