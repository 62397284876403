import React, { useState, useEffect } from "react";
import styled from "styled-components";
// import Http from "../helpers/Http";
import newsImage from "../images/news.png";
import eventImage from "../images/event.png";
import dateFormat from "dateformat";
import axios from "axios";
import { baseApi } from "../utils/routeNames";

const ProgramsAndEvent = () => {
  const [events, setEvents] = useState([]);
  const [news, setNews] = useState([]);
  useEffect(() => {
    try {
      const requestOne = axios.get(baseApi + "content/type/last/3/1");
      const requestTwo = axios.get(baseApi + "content/type/last/4/1");

      axios
        .all([requestOne, requestTwo])
        .then(
          axios.spread((...responses) => {
            setNews(responses[0].data.contents);
            setEvents(responses[1].data.contents);
          })
        )
        .catch((errors) => {
          console.log("errors: ", errors);
        });
    } catch (error) {
      console.log(error);
    }
  }, []);
  return (
    <Container>
      <Title>Events & News</Title>
      <Grid>
        <Events events={events} />
        <News news={news} />
      </Grid>
    </Container>
  );
};

export default ProgramsAndEvent;

const Events = ({ events }) => {
  const now = new Date();
  return events.map((item, key) => (
    <NewsContainer key={key}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}>
        <img src={eventImage} width="100px" height="100px" />
        <NewsCard key={key}>
          <NewsTitle>
            <a
              href={`/detail/?id=${item.id}&type=EVENT`}
              style={{ textDecoration: "none", color: "black" }}>
              {item.title.substring(0, 30)}
            </a>
          </NewsTitle>
          <p style={{ color: "#84ba40" }}>{dateFormat(now, "mmmm dS, yyyy")}</p>
          <NewsDate>
            <div
              dangerouslySetInnerHTML={{
                __html: item.description.substring(0, 100),
              }}
            />
          </NewsDate>
          <a
            href={`/detail/?id=${item.id}&type=EVENT`}
            style={{ textDecoration: "none" }}>
            <Button>Read More</Button>
          </a>
        </NewsCard>
      </div>
    </NewsContainer>
  ));
};

const Button = styled.button`
  padding: 10px;
  background: var(--base-color);
  position: absolute;
  border: 0;
  border-radius: 4px;
  color: #fff;
  margin-top: 15px;
  cursor: pointer;
  bottom: 0;
  display: flex;
  justifycontent: center;
  alignitems: center;
`;

const News = ({ news }) => {
  const now = new Date();
  console.log("news: ", news);
  console.log("date: ", now);
  return news.map((item, key) => (
    <NewsContainer key={key}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}>
        <img src={newsImage} width="100px" height="100px" />
        <NewsCard key={key}>
          <NewsTitle>
            <a
              href={`/detail/?id=${item.id}&type=NEWS`}
              style={{ textDecoration: "none", color: "black" }}>
              {item.title.substring(0, 30)}
            </a>
          </NewsTitle>
          <p style={{ color: "#84ba40" }}>{dateFormat(now, "mmmm dS, yyyy")}</p>
          <NewsDate>
            <div
              dangerouslySetInnerHTML={{
                __html: item.description.substring(0, 100),
              }}
            />
            <a
              href={`/detail/?id=${item.id}&type=NEWS`}
              style={{ textDecoration: "none" }}>
              <Button>Read More</Button>
            </a>
          </NewsDate>
        </NewsCard>
      </div>
    </NewsContainer>
  ));
};

const NewsTitle = styled.p`
  font-size: 16px;
  font-weight: bold;
`;
const NewsDate = styled.time``;

const NewsCard = styled.div`
  min-height: 200px;
  position: relative;
  border-bottom: 1px solid #fff;
  padding: 5px 20px;
  &:last-child {
    border-bottom: 0;
  }
  &:hover {
    ${NewsTitle} {
      cursor: pointer;
      color: var(--base-color);
    }
  }
`;

const NewsContainer = styled.div`
  background-color: rgba(0, 0, 0, 0.025);
  padding: 14px 20px;
`;

const Container = styled.div`
  background-color: #fff;
  padding: 32px 4%;
`;

const Title = styled.h2`
  margin: 0;
  text-align: center;
  text-transform: uppercase;
  font-size: 36px;
  @media (max-width: 480px) {
    font-size: 30px;
  }
`;

const Grid = styled.div`
  max-width: 1024px;
  margin: 4% auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 40px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;
